import {css} from "lit";

export const badgeCss = css` 
    .game-badge {
        width: fit-content;
        border-radius: 20px;
        padding: var(--gff-badge-padding, 3px 8px 2px);
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 140%;
        font-weight: 700;
        margin-bottom: 3px;
        background-blend-mode: multiply;
        background-color: var(--gff-badge-bg-color, rgb(211, 238, 227));
        color: var(--gff-badge-txt-color, rgb(0, 55, 79));
    }
    
    .game-badge:last-child {
        margin-bottom: 0;
    }
    
    .game-badge__icon {
        margin-right: 5px;
    }
    
    .game-badge__pill * {
        padding: 0;
        margin: 0;
    }
`;