import {html, css, TemplateResult} from "lit";
import {property} from "lit/decorators.js";
import {LitElement} from "lit";
import {badgeCss} from "../common/styles/badge.css";

const GT_JACKPOT_UPDATED = 'GT_JACKPOT_UPDATED';

export class GameJackpot extends LitElement {
    static override styles = [
        badgeCss,
        css`
            .game-badge {
                background: var(--gff-badge-jackpot-bg-color);
                border-radius: 4px;
                padding: var(--gff-jackpot-badge-padding, 4px 8px 3px);
            }
            
            .game-badge__text {
                color: var(--gff-badge-jackpot-txt-color);
                font-size: 12px;
            }
      `
    ];

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid= '';

    @property({type: String, attribute: 'jackpot-amount'})
    jackpotAmount = '';

    @property({type: String, attribute: 'jackpot-updated-last'})
    jackpotUpdatedLast = '';

    @property({type: String, attribute: 'jackpot-increment'})
    jackpotIncrement = '';

    @property({type: String, attribute: 'jackpot-increment-tick'})
    jackpotIncrementTick = '';

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent) {
        if (!top?.location.origin.includes(event.origin)) {
            return;
        }

        const eventData = event.data;
        if (eventData.action !== GT_JACKPOT_UPDATED || this.gameUuid !== eventData.gameUuid) {
            return;
        }

        this.jackpotAmount = event.data.payload;
    }

    private isFeatureAvailable(): boolean {
        return !!(this.jackpotAmount);
    }

    override render(): TemplateResult<1> {
        if (!this.isFeatureAvailable()) {
            return html``;
        }

        return html`
            <div class="game-badge game-badge--jackpot">
                <div class="game-badge__text">
                    ${this.jackpotAmount}
                </div>
            </div>
        `;
    }
}

if (!customElements.get('game-jackpot')) {
    customElements.define('game-jackpot', GameJackpot);
}
