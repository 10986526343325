import {msg, str} from '@lit/localize';
import {html, TemplateResult} from "lit";
import {property} from "lit/decorators.js";
import {LitElement} from "lit";
import {badgeCss} from "../common/styles/badge.css";

const BadgeTypes:string[] = [
    'MAINTENANCE',
    'EXCLUSIVE',
    'NEW',
    'HOT',
    'TOURNAMENT'
];

export class GameBadges extends LitElement {
    static override styles = [
        badgeCss
    ]

    @property({type: Array, attribute: 'badges'})
    private badges?: string[];

    override connectedCallback(): void {
        super.connectedCallback();
    }

    override render(): TemplateResult<1>[] {
        const rv:TemplateResult<1>[] = [];
        let count = 0;

        for (const key in BadgeTypes) {
            const value = BadgeTypes[key];

            if (this.badges?.includes(value) && count < 2) {
                rv.push(this.renderSingleBadge(value));
                count++
            }
        }

        return rv;
    }

    private renderSingleBadge(badgeCode:string): TemplateResult<1> {
        return html`
            <div class="game-badge">
                <span class="game-badge__text">
                    ${this.getBadgeTranslation(badgeCode)}
                </span>
            </div>`;
    }

    private getBadgeTranslation(badgeCode:string): string {
        switch (badgeCode) {
            case "MAINTENANCE":
                return msg(str`Maintenance`);
            case "EXCLUSIVE":
                return msg(str`Exclusive`);
            case "NEW":
                return msg(str`NEW!`);
            case "HOT":
                return msg(str`Popular`);
            case "TOURNAMENT":
                return msg(str`Tournament`);
            default:
                return badgeCode;
        }
    }
}

if (!customElements.get('game-badges')) {
    customElements.define('game-badges', GameBadges);
}
