import {TemplateResult} from 'lit';
import {LiveCasino} from "../models/LiveCasino";


export abstract class TileEngine implements Tile {
    protected readonly gameDetails:GameDetails;

    protected constructor(options:GameDetails) {
        this.gameDetails = options;
    }

    public abstract getDecorations(): TemplateResult<1>;
    public abstract getGameInfo(): TemplateResult<1>;
}

export interface Tile {
    getDecorations(): TemplateResult<1>;
    getGameInfo(): TemplateResult<1>;
}

export interface GameDetails {
    gameUuid: string,
    backgroundIconUrl:string,
    displayName: string,
    playerCount: number,
    badges?: string[],
    isFavourite: boolean,
    isLoggedIn: boolean
    liveData: LiveCasino
}