import {GameJourneyAvailability} from "./constants";

/**
 * push an analytics tracking event.
 * @protected
 * @param urlAlias
 * @param launchOrigin
 * @param position
 */
export function getLaunchUrl(urlAlias: string, launchOrigin: string, position: number): string {
    const urlSearchParams = new URLSearchParams({
        launchOrigin: launchOrigin,
        launchOriginUrl: window.location.pathname,
        position: position.toString(),
    });

    return `${window.location.origin}/play/${urlAlias}?${urlSearchParams.toString()}`;
}

export function getAvailabilityParams(): GameJourneyAvailability {
    if (!window.gameJourney || !window.gameJourney.availability) {
        throw new Error(`Game Journey environment isn't configured correctly.`)
    }

    return window.gameJourney.availability;
}