import {html, css, TemplateResult} from "lit";
import {property} from "lit/decorators.js";
import {LitElement} from "lit";
import { LiveGameUpdate } from "../models/LiveCasino";
import {msg, str} from '@lit/localize';

const GT_TABLE_DATA_UPDATED = 'live-casino-data';

export class GamePlayerCount extends LitElement {
    static override styles = [
        css`

        .game-info-active-players {
            font-family: "Lucida Grande","Lucida Sans Unicode","Geneva","Verdana",sans-serif;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            color: var(--gff-game-info-dealer-name-color);
            display: block;
        }
      `
    ];

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid= '';

    @property({type: Number, attribute: 'active-players'})
    private activePlayers= 0;

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent<LiveGameUpdate>) {
        if (!top?.location.origin.includes(event?.origin)) {
            return;
        }
        
        const eventData = event?.data;
        if (eventData?.type !== GT_TABLE_DATA_UPDATED) {
            return;
        }

        if (eventData.message) {
            const updateData = eventData.message[this.gameUuid];
            if(updateData && updateData.activePlayers !== this.activePlayers){
                this.activePlayers = updateData.activePlayers;
            }
        }
    }
    private isFeatureAvailable(): boolean {
        return !!(this.activePlayers && this.activePlayers > 0);
    }

    override render(): TemplateResult<1> {
        if (!this.isFeatureAvailable()) {
            return html``;
        }

        return html`
            <span class="game-info-active-players">${this.activePlayers} ${this.activePlayers === 1 ? msg(str`player`) : msg(str`players`)}</span>
        `;
    }
}

if (!customElements.get('game-player-count')) {
    customElements.define('game-player-count', GamePlayerCount);
}