import {html, css, TemplateResult} from "lit";
import {property} from "lit/decorators.js";
import {LitElement} from "lit";
import {badgeCss} from "../common/styles/badge.css";
import {BlackjackTable, LiveGameUpdate} from "../models/LiveCasino";
import {msg, str} from '@lit/localize';

const GT_TABLE_DATA_UPDATED = 'live-casino-data';

export class GameBlackjackSeats extends LitElement {
    static override styles = [
        badgeCss,
        css`
          .game-badge {
            background: var(--gff-badge-blackjack-bg-color);
            padding: 5px 8px;
            border-radius: 24px;
            margin-bottom: 5px;
          }

          .game-blackjack-icon {
            margin-right: 4px;
            display: flex;
            align-items: center;
          }
          
          .game-blackjack-seats-available {
            font-family: "Lucida Grande","Lucida Sans Unicode","Geneva","Verdana",sans-serif;
            font-size: 10px;
            font-weight: 700;
            line-height: 14px;
            text-align: left;
            color: var(--gff-badge-blackjack-txt-color);
          }
      `
    ];

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid= '';

    @property({type: Object, attribute: 'live-data'})
    private liveData: BlackjackTable = {
        dealer: '',
        type:'BLACKJACK',
        activePlayers: 0,
        enabled: true,
        seats: 0,
        seatsTaken: [],
    };

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent<LiveGameUpdate>) {
        if (!top?.location.origin.includes(event?.origin)) {
            return;
        }

        const eventData = event?.data;
        if (eventData?.type !== GT_TABLE_DATA_UPDATED) {
            return;
        }

        if (eventData.message) {
            const updateData = eventData.message[this.gameUuid];
            if(updateData && updateData.type === this.liveData.type){
                this.liveData = updateData;
            }
        }
    }

    private isFeatureAvailable(): boolean {
        return this.liveData.seats !== 0;
    }

    private getSeatsAvailable(): Number {
        return (this.liveData.seats-this.liveData.seatsTaken.length);
    }

    override render(): TemplateResult<1> {
        if (!this.isFeatureAvailable()) {
            return html``;
        }

        return html`
            <div class="game-badge">
                <span class="game-blackjack-icon">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 1.49976C4.89543 1.49976 4 2.39519 4 3.49976V4.99976C4 6.10433 4.89543 6.99976 6 6.99976C7.10457 6.99976 8 6.10433 8 4.99976V3.49976C8 2.39519 7.10457 1.49976 6 1.49976Z" fill="white"/>
                        <path d="M1.5 10.5232C1.5 8.76335 2.51023 7.23944 3.98241 6.49976C4.43732 7.1203 5.17161 7.52321 6 7.52321C6.82839 7.52321 7.56268 7.1203 8.01759 6.49976C9.48977 7.23944 10.5 8.76335 10.5 10.5232C10.5 11.0086 8.48528 11.7498 6 11.7498C3.51472 11.7498 1.5 11.0435 1.5 10.5232Z" fill="white"/>
                    </svg>
                </span>
                <span class="game-blackjack-seats-available">
                    ${this.getSeatsAvailable() ?? 0}/${this.liveData.seats} ${msg(str`free`)}
                </span>
            </div>
        `;
    }
}

if (!customElements.get('game-blackjack-seats')) {
    customElements.define('game-blackjack-seats', GameBlackjackSeats);
}
