import {html, TemplateResult} from 'lit';
import {GameDetails, TileEngine} from "./Tile";

export class GenericGameTile extends TileEngine {

    public constructor(options:GameDetails) {
        super(options);
    }

    public getDecorations(): TemplateResult<1> {
        return html``;
    }

    public getGameInfo(): TemplateResult<1> {
        return html`
            <game-player-count gameid="${this.gameDetails.gameUuid}" active-players="${this.gameDetails.liveData.activePlayers}" />
        `;
    }
}