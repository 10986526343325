import {html, TemplateResult} from 'lit';
import {GameDetails, TileEngine} from "./Tile";

export class BlackjackGameTile extends TileEngine {
    public constructor(options:GameDetails) {
        super(options);
    }

    public getDecorations(): TemplateResult<1> {
        return html`<game-blackjack-seats live-data=${JSON.stringify(this.gameDetails.liveData)} game-uuid="${this.gameDetails.gameUuid}" />`;
    }

    public getGameInfo(): TemplateResult<1> {
        return html`
            <game-dealer-name live-data=${JSON.stringify(this.gameDetails.liveData)} game-uuid="${this.gameDetails.gameUuid}">
        `;
    }
}