import {html, css, TemplateResult} from "lit";
import {property} from "lit/decorators.js";
import {LitElement} from "lit";
import {infoCss} from "../common/styles/info.css";
import {LiveCasino, LiveGameUpdate} from "../models/LiveCasino";

const GT_TABLE_DATA_UPDATED = 'live-casino-data';

export class GameDealerName extends LitElement {
    static override styles = [
        infoCss,
        css`
        .game-info-dealer {
            font-family: "Lucida Grande","Lucida Sans Unicode","Geneva","Verdana",sans-serif;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            color: var(--gff-game-info-dealer-name-color);
            display: block;
        }`
    ];

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid= '';

    @property({type: Object, attribute: 'live-data'})
    private liveData : LiveCasino = {
        type:'',
        dealer: '',
        activePlayers: 0,
        enabled: true,
    };

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent<LiveGameUpdate>) {
        if (!top?.location.origin.includes(event?.origin)) {
            return;
        }

        const eventData = event?.data;
        if (eventData?.type !== GT_TABLE_DATA_UPDATED) {
            return;
        }

        if (eventData.message) {
            const updateData = eventData.message[this.gameUuid];
            if(updateData && updateData.type === this.liveData.type){
                this.liveData = updateData;
            }
        }
    }

    private isFeatureAvailable(): boolean {
        return !!(this.liveData.dealer);
    }

    override render(): TemplateResult<1> {
        if (!this.isFeatureAvailable()) {
            return html``;
        }

        return html`
            <span class="game-info-dealer">
                ${this.liveData.dealer}
            </span>
        `;
    }
}

if (!customElements.get('game-dealer-name')) {
    customElements.define('game-dealer-name', GameDealerName);
}
