import {html, TemplateResult} from 'lit';
import {GameDetails, TileEngine} from "./Tile";

export class BaccaratGameTile extends TileEngine {
    public constructor(options:GameDetails) {
        super(options);
    }

    public getDecorations(): TemplateResult<1> {
        return html`<game-baccarat-badges live-data=${JSON.stringify(this.gameDetails.liveData)} game-uuid="${this.gameDetails.gameUuid}" />`;
    }

    public getGameInfo(): TemplateResult<1> {
        return html`<game-player-count active-players=${JSON.stringify(this.gameDetails.liveData.activePlayers)} game-uuid="${this.gameDetails.gameUuid}">`;
    }
}