import {msg, str} from '@lit/localize';
import {html, LitElement, TemplateResult} from "lit";
import {property, state} from "lit/decorators.js";
import {actionsCss} from "../common/styles/actions.css";
import {issueGameTileMessage, issueToastMessage, pushToDataLayer} from "../common/communication";
import {classMap} from "lit/directives/class-map.js";

const GT_FAVOURITE_UPDATED = 'GT_FAVOURITE_UPDATED';

let bigHeartAnimationTimeout:setTimeout;
let smallHeartAnimationTimeout:setTimeout;

export class GameFavourite extends LitElement {
    static override styles = [
        actionsCss
    ];

    private readonly TOAST_TITLE_SUCCESS: string = msg(str`Success!`);
    private readonly TOAST_TITLE_FAILURE: string = msg(str`Failure!`);
    private TOAST_TEXT_ADD_SUCCESS = '';
    private TOAST_TEXT_ADD_FAILURE = '';
    private TOAST_TEXT_REMOVE_SUCCESS = '';
    private TOAST_TEXT_REMOVE_FAILURE = '';

    @state()
    private _isDisabled = false;

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid= '';

    @property({type: String, attribute: 'display-name'})
    private displayName = '';

    @property({type: String, attribute: 'position'})
    private position = '';

    @property({type: Boolean, attribute: 'is-favourited'})
    private isFavourite = false;

    @property({type: Boolean, attribute: 'ongoing-big-animation'})
    private ongoingBigAnimation = false;

    @property({type: Boolean, attribute: 'ongoing-small-animation'})
    private ongoingSmallAnimation = false;

    @property({type: Boolean, attribute: 'is-logged-in'})
    private isLoggedIn = false;

    @property({type: String, attribute: 'launch-origin'})
    private launchOrigin = '';

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
        this.loadToastPrompts();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    override render(): TemplateResult<1> {
        if (!this.isFeatureAvailable()) {
            return html``;
        }

        return html`
            <div class="touch-target">
                <a role="button" class="touch-target__action touch-target__action--favourite" @click="${this.toggleFavourite}"></a>
                <div class="favourite-action__icon ${classMap({
                    'favourite-action__icon--active': this.isFavourite,
                    'favourite-action-icon--big-animation': this.ongoingBigAnimation,
                    'favourite-action-icon--small-animation': this.ongoingSmallAnimation,
            })}"></div>
            </div>
        `;
    }

    private loadToastPrompts(): void {
        this.TOAST_TEXT_ADD_SUCCESS = msg(str`Added ${this.displayName} to your favourites.`);
        this.TOAST_TEXT_ADD_FAILURE = msg(str`Could not add ${this.displayName} to your favourites.`);
        this.TOAST_TEXT_REMOVE_SUCCESS = msg(str`Removed ${this.displayName} from your favourites.`);
        this.TOAST_TEXT_REMOVE_FAILURE = msg(str`Could not remove ${this.displayName} from your favourites.`);
    }

    private isFeatureAvailable(): boolean {
        return !!(this.isLoggedIn && top?.JWToken);
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent) {
        if (!top?.location.origin.includes(event.origin)) {
            return;
        }

        const eventData = event.data;
        if (eventData.action !== GT_FAVOURITE_UPDATED || this.gameUuid !== eventData.gameUuid) {
            return;
        }

       this.isFavourite = eventData.payload;

       if (this.isFavourite) {
        this.ongoingBigAnimation = true;
        bigHeartAnimationTimeout = setTimeout(() => this.ongoingBigAnimation = false , 800);
       } else {
        this.ongoingSmallAnimation = true;
        smallHeartAnimationTimeout = setTimeout(() => this.ongoingSmallAnimation = false , 100);
       }

    }

    private async toggleFavourite() {
        if (this._isDisabled) {
            return;
        }

        let TOAST_TITLE = '';
        let TOAST_TEXT = '';

        // Disable the input element until the request has been completed
        this._isDisabled = true;
        const newState = !this.isFavourite;


        try {
            // Issue a request to mark/unmark the given game as a favourite
            // Uses the state of the input element to determine which HTTP method to use
            const response = await fetch(
                this.getPostbackUrl(),
                {
                    method: newState ? 'POST' : 'DELETE',
                    headers: {Authorization: `Bearer ${top?.JWToken}`},
                }
            );

            if (response.ok) {
                issueGameTileMessage(GT_FAVOURITE_UPDATED, this.gameUuid, newState)

                // raise analytics event
                this.pushTracking(newState);

                TOAST_TITLE = this.TOAST_TITLE_SUCCESS;
                TOAST_TEXT = newState
                    ? this.TOAST_TEXT_ADD_SUCCESS
                    : this.TOAST_TEXT_REMOVE_SUCCESS;
            } else {
                TOAST_TITLE = this.TOAST_TITLE_FAILURE;
                TOAST_TEXT = newState
                    ? this.TOAST_TEXT_ADD_FAILURE
                    : this.TOAST_TEXT_REMOVE_FAILURE;
            }
        } catch {
            TOAST_TITLE = this.TOAST_TITLE_FAILURE;
            TOAST_TEXT = newState
                ? this.TOAST_TEXT_ADD_FAILURE
                : this.TOAST_TEXT_REMOVE_FAILURE;

        } finally {
            // Enable the input element when the request has been completed
            this._isDisabled = false;

            issueToastMessage(TOAST_TITLE, TOAST_TEXT);
        }
    }

    private getPostbackUrl(): string {
        return `${this.getHost()}/ajax/favourites/${this.gameUuid}`;
    }

    private getHost() {
        return (window.location.hostname !== 'localhost')
            ? '/fragment/gamefilter'
            : '';
    }

    private pushTracking(newState:boolean): void {
        pushToDataLayer({
            event: 'favourite',
            favourite: newState,
            gameName: this.displayName,
            gamePosition: this.position,
            origin: this.launchOrigin
        });
    }
}

if (!customElements.get('game-favourite')) {
    customElements.define('game-favourite', GameFavourite);
}