import {GameJourney} from "./constants";

/**
 * push an analytics tracking event.
 * @param payload
 * @protected
 */
export function pushToDataLayer(payload: Record<string, string | number | boolean | undefined | (() => void)>): void {
    if (typeof window.dataLayer?.push !== 'function' || !payload) {
        return;
    }

    if (window.gameJourney?.availability) {
        const availability = window.gameJourney.availability;

        payload['launch-origin-site']= availability.siteCode;
        payload['launch-origin-channel']= availability.channel;
        payload['launch-origin-language']= availability.language;
        payload['launch-origin-currency']= availability.currency;
        payload['launch-origin-country']= availability.country;
    }

    // browser data:
    payload['originUrl'] = window.location.pathname;

    window.dataLayer.push(payload);
}

/**
 * push a message to the notification-fragment
 * @param toastTitle
 * @param toastText
 */
export function issueToastMessage(toastTitle: string, toastText: string): void {
    postMessage({
        channel: 'INSTANT_TOAST',
        title: toastTitle,
        text: toastText
    });
}

/**
 * push a message back to the game-tile for internal changes.
 * @param action
 * @param gameUuid
 * @param payload
 */
export function issueGameTileMessage(action: string, gameUuid:string, payload:object|boolean|string|number) {
    postMessage({ action, gameUuid, payload })
}

export function issueLaunchGameEvent(origin: string, gameName: string, position = 1, event = "game_trigger--tile"):void {
    const payload: Record<string, string | number | boolean | undefined | (() => void)> = {
        origin,
        event,
        gameName,
        gamePosition: position.toString()
    };

    if(window.gameJourney?.lobby){
        payload['launch-origin-lobby'] = window.gameJourney.lobby;
    }
    if(window.gameJourney?.page){
        payload['launch-origin-page'] = window.gameJourney.page;
    }
    
    pushToDataLayer(payload)
}

function postMessage(payload:object) {
    top?.postMessage(payload, top?.origin);
}

declare global {
    interface Window {
        launchOrigin?: '';
        dataLayer?: unknown[];
        gameJourney?: GameJourney
        JWToken?: string;
    }
}