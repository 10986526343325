import {css, html, LitElement, TemplateResult} from "lit";
import {property} from "lit/decorators.js";
import {badgeCss} from "../common/styles/badge.css";
import {LiveGameUpdate, RouletteTable} from "../models/LiveCasino";

const GT_TABLE_DATA_UPDATED = 'live-casino-data';

export class GameRouletteBadges extends LitElement {
    static override styles = [
        badgeCss,
        css`

        :host {
            container-type: inline-size;
            container-name: roulette-badges;
            display: block;
        }
            
        .game-roulette-wrapper {
            margin-bottom: 5px;
            display: flex;
        }

        .game-roulette-badges {
            width: 24px;
            height: 24px;
            border-radius: 24px;
            font-size: 10px;
            line-height: 14px;
            display: none;
            place-content: center;
            font-weight: 700;
            text-align: center;
            margin: 0 2px 2px 0;
            color: #FFF;
            font-family: "Lucida Grande","Lucida Sans Unicode","Geneva","Verdana",sans-serif;
        }

        .game-roulette-badges:nth-child(-n+4) {
            display: grid;
        }

        @container roulette-badges (min-width: 131px) {
            .game-roulette-badges:nth-child(-n+5) {
                display: grid;
            }
        }

        @container roulette-badges (min-width: 156px) {
            .game-roulette-badges:nth-child(-n+6) {
                display: grid;
            }
        }

        @container roulette-badges (min-width: 181px) {
            .game-roulette-badges:nth-child(-n+7) {
                display: grid;
            }
        }

        @container roulette-badges (min-width: 206px) {
            .game-roulette-badges:nth-child(-n+8) {
                display: grid;
            }
        }

        @container roulette-badges (min-width: 231px) {
            .game-roulette-badges:nth-child(-n+9) {
                display: grid;
            }
        }

        @container roulette-badges (min-width: 256px) {
            .game-roulette-badges:nth-child(-n+10) {
                display: grid;
            }
        }

        .game-roulette-badges.red {
            background-color: #EB003C;
        }
        
        .game-roulette-badges.black {
            background-color: #000000;
        }
        
        .game-roulette-badges.green {
            background-color: #008A16;
        }
      `
    ];

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid= '';

    @property({type: Object, attribute: 'live-data'})
    private liveData: RouletteTable = {
        dealer:'',
        type: "ROULETTE",
        activePlayers: 0,
        enabled: true,
        results: [],
    };

    private greenNumbers = ['0', '00'];
    private redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
    private blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
    
    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent<LiveGameUpdate>) {
        if (!top?.location.origin.includes(event?.origin)) {
            return;
        }

        const eventData = event?.data;
        if (eventData?.type !== GT_TABLE_DATA_UPDATED) {
            return;
        }

        if (eventData.message) {
            const updateData = eventData.message[this.gameUuid];
            if(updateData && updateData.type === this.liveData.type){
                this.liveData = updateData;
            }
        }
    }

    private renderRouletteBadges() {
        return this.liveData.results.map((result, index)  => {
            const className = this.selectColorClass(result);
            if(className !== 'invalid') {
                return html`
                    <div class="game-roulette-badges ${className}">
                        ${ result }
                    </div>
                `
            }
            return html``;
        });
    }

    private selectColorClass(result:string) {
        if(this.greenNumbers.includes(result)){
            return 'green';
        }else if(this.redNumbers.includes(result)) {
            return 'red';
        }else if(this.blackNumbers.includes(result)) {
            return 'black';
        }

        return 'invalid';
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="game-roulette-wrapper">
                ${ this.renderRouletteBadges() }
            </div>
        `;
    }
}

if (!customElements.get('game-roulette-badges')) {
    customElements.define('game-roulette-badges', GameRouletteBadges);
}

