import {css} from "lit";

export const gameCss = css`
    .game-item {
        background-repeat: no-repeat;
        background-size: 145px, cover;
        background-position: center center;
        background-color: var(--gff-game-tile-bg-color);
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        height: 100%;
        flex-direction: column;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        font-size: 14px;
        line-height: 140%;
        border: var(--gff-game-tile-border);
        box-sizing: border-box;
    }

    .game-item * {
        font-family: var(--gff-game-tile-font);
    }

    .game-item:hover .game-item-foreground-container {
        top: var(--gff-game-tile-fg-animation-jump);
    }

    .game-item:hover .game-item-foreground-container.overlay-active {
        top: 0;
    }

    .game-item-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    
    .game-item-foreground-container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        transition: top .2s ease-in-out 0s;
    }
    
    .game-item-foreground-position {
        padding: var(--gff-game-tile-foreground-padding, 15px);
        box-sizing: border-box;
        max-height: 226px;
        overflow: hidden;
    }

    .game-item-foreground-container,
    .game-item-foreground-position {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .foreground-minimize-animation {
        transition: transform 0.3s ease-in-out;
        transform-origin: calc(100% - 10px) top;
        transform: scale(0.20);
        z-index: 100;
    }

    .foreground-restore-animation {
        transition: transform 0.3s ease-in-out;
        transform-origin: top right;
        transform: scale(1);
        z-index: -1;
    }

    .game-item-foreground-position,
    .game-item-foreground {
        aspect-ratio: var(--gff-game-tile-aspect-ratio, 1 / 1);
        object-fit: contain;
    }
    
    .game-item-foreground {
        border-radius: var(--gff-game-tile-fg-image-radius);
        background: var(--gff-game-tile-fg-image-bg);
        z-index: -1;
        width: 100%;
        max-height: var(--gff-game-tile-foreground-max-height, 100%);
        padding: 3px;
    }

    .game-badges-holder {
        width: 100%;
    }
    
    .game-info__more a {
        font-weight: bold;
        color: #fff;
        text-decoration: none;
    }

    .game-item__footer {
        background: var(--gff-game-tile-footer-gradient);
        background-blend-mode: multiply;
        color: var(--gff-game-tile-footer-text);
    }

    .game-item__decorations { padding: 12px; }
         
    .game-action {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
    
    .game-item,
    .game-info,
    .game-item__decorations {
        display: flex;
        justify-content: space-between;
    }
    
    .game-info {
        align-items: flex-end;
        padding: 8px 12px 12px;
    }

    .game-item__footer .game-item__decorations { padding-bottom: 0; }
    
    .game-info__name { 
        padding-right: 10px;
        overflow: hidden;
    }
`;