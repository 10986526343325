import {html, LitElement, nothing, TemplateResult} from 'lit';
import {property} from "lit/decorators.js";
import {GameDetails, Tile} from "./engines/Tile";
import {GenericGameTile} from "./engines/generic-game-tile";
import {BlackjackGameTile} from "./engines/blackjack-game-tile";
import {BaccaratGameTile} from "./engines/baccarat-game-tile";
import {RouletteGameTile} from "./engines/roulette-game-tile";
import {issueLaunchGameEvent} from "./common/communication";
import {infoCss} from "./common/styles/info.css";
import {gameCss} from "./common/styles/game.css";
import {badgeCss} from "./common/styles/badge.css";
import {CONTAINER, CONTAINER__COLUMN, FONT_WEIGHT_700} from "./common/styles/global.css";
import {getLaunchUrl} from "./common/game-utils";
import {GameJourney} from "./common/constants";
import {LiveCasino} from "./models/LiveCasino";
import {classMap} from 'lit/directives/class-map.js';

/**
 * 'require' is a node.js function and doesn't exist in the test suite. since all
 * components are tested individual we only need to include these at compile time.
 */
if (typeof require !== 'undefined') {
    require("./components/game-badges");
    require("./components/game-info-overlay");
    require("./components/game-favourite");
    require("./components/game-jackpot");
    require("./components/game-player-count");
    require("./components/game-blackjack-seats");
    require("./components/game-dealer-name");
    require("./components/game-baccarat-badges");
    require("./components/game-roulette-badges");
}

export class GameTile extends LitElement {
    static override styles = [
        FONT_WEIGHT_700,
        CONTAINER, CONTAINER__COLUMN,
        infoCss,
        gameCss,
        badgeCss
    ]

    private engine:Tile|undefined;

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid = '';

    @property({type: String, attribute: 'url-alias'})
    private urlAlias = '';

    @property({type: String, attribute: 'background-icon-url'})
    private backgroundIconUrl = '';

    @property({type: String, attribute: 'foreground-icon-url'})
    private foregroundIconUrl = '';

    @property({type: String, attribute: 'icon-url'})
    private iconUrl = '';

    @property({type: String, attribute: 'display-name'})
    private displayName = '';

    @property({type: Number, attribute: 'player-count'})
    private playerCount = 0;

    @property({type: Array, attribute: 'badges'})
    private badges?: string[];

    @property({type: Boolean, attribute: 'is-favourited'})
    private isFavourite = false;

    @property({type: Boolean, attribute: 'is-logged-in'})
    private isLoggedIn = false;

    @property({type: Number, attribute: 'position'})
    private position = 0;

    @property({type: Number, attribute: 'site-id'})
    private siteId = 1;

    @property({type: String, attribute: 'jackpot-amount'})
    private jackpotAmount = '';

    @property({type: String, attribute: 'jackpot-updated-last'})
    private jackpotUpdatedLast = '';

    @property({type: String, attribute: 'jackpot-increment'})
    private jackpotIncrement = '';

    @property({type: String, attribute: 'jackpot-increment-tick'})
    private jackpotIncrementTick = '';

    @property({type: String, attribute: 'launch-origin'})
    private launchOrigin = '';

    @property({type: Boolean, attribute: 'visible'})
    private isVisible = false;

    @property({type: Boolean, attribute: 'overlay-active'})
    private overlayActive = false;

    @property({type: Boolean, attribute: 'overlay-inactive'})
    private overlayInactive = false;

    @property({type: Object, attribute: 'live-data'})
    private liveData: LiveCasino = {
        dealer:'',
        type:'',
        activePlayers: 0,
        enabled: true,
    };

    override connectedCallback(): void {
        super.connectedCallback();

        switch (this.liveData?.type) {
            case "BLACKJACK":
                this.engine = new BlackjackGameTile(this.getGameDetails());
                break;
            case "BACCARAT":
                this.engine = new BaccaratGameTile(this.getGameDetails());
                break;
            case "ROULETTE":
                this.engine = new RouletteGameTile(this.getGameDetails());
                break;
            default:
                this.engine = new GenericGameTile(this.getGameDetails());
                break;
        }

        if (this.liveData.type && window.liveCasinoWorker) {
            window.liveCasinoWorker.postMessage({ eventType: 'subscribe', gameId: this.gameUuid});
        }

        this.lazyInitialization();
    }

    override render(): TemplateResult<1> {
        const launchUrl = getLaunchUrl(this.urlAlias, this.launchOrigin, this.position);

        return html`
            <div class="game-item" style="background-image: ${this.getBackground()};">
                <div class="game-item-foreground-container ${classMap({
                    'overlay-active': this.overlayActive,
                })}">
                    <div class="game-item-foreground-position ${classMap({
                        'foreground-minimize-animation': this.overlayActive,
                        'foreground-restore-animation': this.overlayInactive,
                    })}">
                        ${this.getForeground()}    
                    </div>
                    
                </div>
                <div class="game-item-content">
                    <a href="${launchUrl}"
                       @click="${this.gameLaunchHandler}"
                       title="${this.displayName}"
                       target="_top"
                       class="game-action overflow-hidden"
                       rel="nofollow"
                       game-icon-anchor>
                    </a>
                    
                    <div class="game-item__decorations">
                        <div class="game-badges-holder">
                            ${this.engine?.getDecorations()}
                            <game-badges .badges='${this.badges}' />
                        </div>
                        <div>
                            <game-favourite
                                    game-uuid="${this.gameUuid}"
                                    display-name="${this.displayName}"
                                    launch-origin="${this.launchOrigin}"
                                    position="${this.position}"
                                    ?is-favourited="${this.isFavourite}" 
                                    ?is-logged-in="${this.isLoggedIn}" />
                        </div>
                    </div>
                    <div class="game-item__footer">
                        <div class="game-item__decorations">
                            <game-jackpot
                                    game-uuid="${this.gameUuid}"
                                    jackpot-amount="${this.jackpotAmount}"
                                    jackpot-increment="${this.jackpotIncrement}"
                                    jackpot-increment-tick="${this.jackpotIncrementTick}"
                                    jackpot-updated-last="${this.jackpotUpdatedLast}">
                            </game-jackpot>
                        </div>
                        
                        <div class="game-info">
                            <div class="game-info__name container container--column">
                                <span class="font-weight-700">${this.displayName}</span>
                                    ${ this.engine?.getGameInfo() }
                            </div>
                            <game-info-overlay 
                                    game-uuid="${this.gameUuid}" 
                                    url-alias="${this.urlAlias}"
                                    display-name="${this.displayName}"
                                    launch-origin="${this.launchOrigin}"
                                    position="${this.position}" 
                                    live-data="${JSON.stringify(this.liveData)}"
                                    @overlay-is-active="${this.overlayIsActive}"
                                    @overlay-is-inactive="${this.overlayIsInactive}">
                            </game-info-overlay>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    /**
     * handle lazy initialization of the element to only render when the tile is first exposed in the viewport.
     *
     * note: if the tile is configured to be automatically visible, or the IntersectionObserve is not available the tile
     * will be shown by default
     * @private
     */
    private lazyInitialization() {
        if (this.isVisible || !("IntersectionObserver" in window)) {
            this.isVisible = true;
            return;
        }

        const renderObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    (entry.target as GameTile).isVisible = true;
                    renderObserver.unobserve(entry.target);
                }
            });
        });

        renderObserver.observe(this);
    }

    private getGameDetails(): GameDetails {
        return {
            gameUuid: this.gameUuid,
            playerCount: this.playerCount,
            backgroundIconUrl: this.backgroundIconUrl,
            displayName: this.displayName,
            badges: this.badges,
            isFavourite: this.isFavourite,
            isLoggedIn: this.isLoggedIn,
            liveData: this.liveData,
        }
    }

    private gameLaunchHandler = () => {
        issueLaunchGameEvent(this.launchOrigin, this.displayName, this.position);
    }

    private getBackground(): string {
        if (!this.isVisible) { return ''; }

        if (this.siteId === 209) {
            return '';
        }

        if (!this.foregroundIconUrl) {
            return `url(""),url("${this.iconUrl}");`
        }

        return `url(""),url("${this.backgroundIconUrl}");`
    }

    private getForeground() {
        if (!this.isVisible) { return nothing; }

        let foregroundIconUrl = this.foregroundIconUrl;

        if (this.siteId === 209) {
            foregroundIconUrl = this.iconUrl;
        }

        if (!foregroundIconUrl) { return nothing; }

        return html`<img class="game-item-foreground" src="${foregroundIconUrl}" alt="${this.displayName}"/>`;
    }

    private overlayIsActive(_event:CustomEvent) {
        this.overlayActive = true;
        this.overlayInactive = false;
    }

    private overlayIsInactive(_event:CustomEvent) {
        this.overlayActive = false;
        this.overlayInactive = true;
    }
}

declare global {
    interface postMessage {
        (event: object): void
    }

    interface Window {
        dataLayer?: unknown[];
        launchOrigin?: '';
        gameJourney?: GameJourney,
        liveCasinoWorker?: ServiceWorker,
    }
}

if (!customElements.get('game-tile')) {
    customElements.define('game-tile', GameTile);
}