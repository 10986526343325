import {css, html, LitElement, TemplateResult} from "lit";

import {msg, str} from '@lit/localize';
import {classMap} from 'lit/directives/class-map.js';
import {property, state} from "lit/decorators.js";
import {actionsCss} from "../common/styles/actions.css";
import {issueLaunchGameEvent, pushToDataLayer} from "../common/communication";
import {GameInfo} from "../models/GameInfo";
import {FONT_WEIGHT_700} from "../common/styles/global.css";
import {getAvailabilityParams, getLaunchUrl} from "../common/game-utils";
import {LiveCasino} from "../models/LiveCasino";

const OVERLAY_OPEN_TRACKING_EVENT = 'gametile opened';
const OVERLAY_CLOSE_TRACKING_EVENT = 'gametile closed';

export class GameInfoOverlay extends LitElement {
    static override styles = [
        actionsCss,
        FONT_WEIGHT_700,
        css`
            .game-tile-context-menu {
                box-sizing: border-box;
                z-index: 3;
                padding: 12px;
                font-size: 14px;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 100%;
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                background: var(--gff-overlay-bg-color);
                background-blend-mode: multiply;
                content: '';
                transition: top 0.3s ease-out;
            }

            .game-tile-context-menu--active {
                top: 0;
            }
            
            .game-tile-context-menu > div {
                z-index: 1;
            }
            
            .game-tile-context-menu,
            .game-tile-context-menu__header,
            .game-tile-context-menu__footer {
                justify-content: space-between;
            }
            
            .game-tile-context-menu__header,
            .game-tile-context-menu__footer {
                align-items: center;
            }
            
            .game-tile-context-menu__header__game-icon,
            .game-tile-context-menu__header__game-icon img {
                width: 44px;
                height: 44px;
            }
                        
            .game-info--subscript {
                font-size: 10px;
                line-height: 140%;
            }
            
            .game-tile-context-menu__body {
                flex: 1;
                padding-top: 8px;
            }
                        
            .game-info__name,
            .game-tile-context-menu,
            .game-tile-context-menu__body,
            .game-tile-stats-container__stat {
                flex-direction: column;
            }

            .game-info__name {
                font-size: 12px;
                max-width: var(--gff-overlay-game-info-name-max-width);
            }
            
            .game-info__name,
            .game-tile-context-menu,
            .game-tile-context-menu__body,
            .game-tile-context-menu,
            .game-tile-context-menu__header,
            .game-tile-context-menu__footer,
            .game-tile-stats-container,
            .game-tile-stats-container__stat {
                display: flex;
            }
            
            .game-tile-stats-container {
                padding: 4px 0;
                border-top: solid 1px var(--gff-overlay-stat-border-color, #fff);
                line-height: 19.6px;
            }
            
            .game-tile-stats-container__stat {
                padding-right: 10px;
                border-right: solid 1px var(--gff-overlay-stat-border-color, #fff);
                margin-right: 10px;
            }
            
            .game-tile-stats-container__stat:last-child {
                border-right: 0;
                margin-right: 0;
            }

            .play-button {
                background: var(--gff-button-bg-color);
                color: var(--gff-button-txt-color);
                border-radius: var(--gff-button-radius);
                border: var(--gff-button-border);
                cursor: pointer;
                padding: 7px;
                font-size: 12px;
                height: 30px;
                text-decoration: none;
                box-sizing: border-box;
            }
            
            .play-button:hover,
            .play-button:active,
            .play-button:focus {
                background: var(--gff-button-bg-color-hover);
                border-width: var(--gff-button-border-width--hover);
            }
            
            .skeleton {
                height: 8px;
                border-radius: 2px;
                background: var(--gff-skeleton-bg, #00648F);
                background-size: 200% 100%;
                margin-bottom: 6px;
                animation: 1.5s shine linear infinite;
            }

            .skeleton--tall {
                height: 14px;
            }

            .skeleton:last-child { margin-bottom: 0; }

            .skeleton--sml {
                width: 40px;
            }
            
            .skeleton--md {
                width: 60px;
            }

            .skeleton--lrg {
                width: 80px;
            }
    
            .game-info--subscript {
                color: var(--gff-overlay-stat-title-text-color) 
            }

            @keyframes shine {
                to {
                    background-position-x: -200%;
                }
            }
        `
    ];

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid= '';

    @property({type: String, attribute: 'url-alias'})
    private urlAlias = '';

    @property({type: Object, attribute: 'live-data'})
    private liveData : LiveCasino = {
        type:'',
        dealer: '',
        activePlayers: 0,
        enabled: true,
    };

    @property({type: String, attribute: 'display-name'})
    private displayName = '';

    @property({type: String, attribute: 'launch-origin'})
    private launchOrigin = '';

    @property({type: Number, attribute: 'position'})
    private position = 0;

    @state()
    private isActive = false;

    @state()
    private isComplete = false;

    @state()
    private hasFailed = false;

    @state()
    private betRange = '';

    @state()
    private realRTP = '';

    @state()
    private theoreticalRTP = '';

    @state()
    private launchUrl = '';

    private abortController:AbortController|null = null;

    override connectedCallback(): void {
        super.connectedCallback();

        this.launchUrl = getLaunchUrl(this.urlAlias, this.getLaunchOrigin(), this.position);
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="game-info__more">
                <div class="touch-target touch-target--themed">
                    <button class="touch-target__action touch-target__action--open" tabindex="1" @click="${this.toggleGameInfoOverlay}" @keyup="${this.toggleGameInfoOverlayKeyUp}"></button>
                    <svg width="12" height="4" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2C3 2.82843 2.32843 3.5 1.5 3.5C0.671573 3.5 0 2.82843 0 2C0 1.17157 0.671573 0.5 1.5 0.5C2.32843 0.5 3 1.17157 3 2ZM12 2C12 2.82843 11.3284 3.5 10.5 3.5C9.67157 3.5 9 2.82843 9 2C9 1.17157 9.67157 0.5 10.5 0.5C11.3284 0.5 12 1.17157 12 2ZM6 3.5C6.82843 3.5 7.5 2.82843 7.5 2C7.5 1.17157 6.82843 0.5 6 0.5C5.17157 0.5 4.5 1.17157 4.5 2C4.5 2.82843 5.17157 3.5 6 3.5Z" />
                    </svg>
                </div>
            </div>
            ${this.getContextMenu()}
        `;
    }

    private getMenuClassMap() {
        return classMap({
            'game-tile-context-menu--active': this.isActive,
        });
    }

    private getContextMenu() {
        return html`
            <div class="game-tile-context-menu ${this.getMenuClassMap()}">
                <div class="game-tile-context-menu__header">
                    <div class="game-info__name">
                        <span class="font-weight-700">${this.displayName}</span>
                        <game-player-count gameid="${this.gameUuid}" active-players="${this.liveData.activePlayers}" />
                    </div>
                </div>
                <div class="game-tile-context-menu__body">
                    ${this.getBody()}
                </div>
                <div class="game-tile-context-menu__footer">
                    <div>
                        <a href="${this.launchUrl}"
                        @click="${this.gameLaunchHandler}"
                        title="${this.displayName}" 
                        class="play-button" 
                        target="_top" 
                        rel="nofollow"
                        >
                            ${msg(str`Play game`)}
                        </a>
                    </div>
                    <div>
                        <div class="touch-target touch-target--themed">
                            <a role="button" class="touch-target__action touch-target__action--close" @click="${this.toggleGameInfoOverlay}" @keyup="${this.toggleGameInfoOverlayKeyUp}" tabindex="1"></a>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.195262 0.195262C0.455612 -0.0650874 0.877722 -0.0650874 1.13807 0.195262L6 5.05719L10.8619 0.195262C11.1223 -0.0650874 11.5444 -0.0650874 11.8047 0.195262C12.0651 0.455612 12.0651 0.877722 11.8047 1.13807L6.94281 6L11.8047 10.8619C12.0651 11.1223 12.0651 11.5444 11.8047 11.8047C11.5444 12.0651 11.1223 12.0651 10.8619 11.8047L6 6.94281L1.13807 11.8047C0.877722 12.0651 0.455612 12.0651 0.195262 11.8047C-0.0650874 11.5444 -0.0650874 11.1223 0.195262 10.8619L5.05719 6L0.195262 1.13807C-0.0650874 0.877722 -0.0650874 0.455612 0.195262 0.195262Z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    private getBody() {
        if (this.hasFailed) {
            return html`
                <div class="game-tile-stats-container game-tile-stats-container--error">
                    <div class="game-tile-stats-container__stat game-info--subscript">
                        ${msg(str`No information available`)}    
                    </div>
                </div>
                
                <div class="game-tile-stats-container__error">
                    
                </div>
            `;
        }

        return html`
            ${this.getRtp()}
            ${this.getBetRange()}
        `;
    }

    private getRtp() {
        if (this.isComplete && (!this.theoreticalRTP && !this.realRTP)) {
            return html``
        }

        return html`
            <div class="game-tile-stats-container">
                ${(this.theoreticalRTP) ? this.getStatistic(msg(str`Theoretical RTP:`), this.theoreticalRTP) : this.getSkeleton('sml', 'md') }
                ${(this.realRTP) ? this.getStatistic(msg(str`RTP (24h):`), this.realRTP) : this.getSkeleton('sml', 'lrg') }
            </div>
        `;
    }

    private getBetRange() {
        if (this.isComplete && !this.betRange) {
            return html``
        }

        return html`
            <div class="game-tile-stats-container">
                ${(this.betRange) ? this.getStatistic(msg(str`Bet range:`), this.betRange) : this.getSkeleton('md', 'lrg') }
            </div>
        `;
    }

    private getSkeleton(titleSize:string, valueSize:string) {
        if (this.isComplete) {
            return html``;
        }

        return html`
            <div class="game-tile-stats-container__stat">
                <div class="skeleton skeleton--${titleSize}"></div>
                <div class="skeleton skeleton--tall skeleton--${valueSize}"></div>
            </div>
        `
    }

    private getStatistic(title:string, value:string): TemplateResult<1> {
        if (!value || value === '') return html``;

        return html`
            <div class="game-tile-stats-container__stat">
                <span class="game-info--subscript">${title}</span>
                <span>${value}</span>
            </div>
        `
    }

    private async toggleGameInfoOverlay() {
        try {
            if (this.isActive) {
                return this.closeGameInfoOverlay();
            }

            return await this.openGameInfoOverlay();
        } catch (error) {
            this.hasFailed = true;

        } finally {
            this.abortController = null;
        }
    }

    private async toggleGameInfoOverlayKeyUp(e: KeyboardEvent) {
        if (['Enter', 'NumpadEnter'].indexOf(e.code) == -1) return;

        await this.toggleGameInfoOverlay();
    }

    private async openGameInfoOverlay(): Promise<void> {
        this.isActive = true;
        this.dispatchEvent(new CustomEvent('overlay-is-active', {bubbles: true}));

        this.pushTracking(OVERLAY_OPEN_TRACKING_EVENT);

        this.abortController = new AbortController();

        const result = await fetch(
            this.getPostbackUrl(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${top?.JWToken}`,

                },
                signal: this.abortController.signal,
            }
        );

        if (!result.ok) {
            throw new Error('Could not fetch gametileMenu data');
        }

        const data:GameInfo = await result.json();
        this.unpackResponse(data);

        // loading is completed;
        this.isComplete = true;
    }

    /**
     * close the game-info overlay and submit an analytics event.
     * @private
     */
    private closeGameInfoOverlay(): void {
        this.isActive = false,
        this.hasFailed = false,
        this.isComplete = false,
        this.dispatchEvent(new CustomEvent('overlay-is-inactive', {bubbles: true}));

        // reset state
        this.betRange = '';
        this.realRTP = '';
        this.theoreticalRTP = '';

        this.pushTracking(OVERLAY_CLOSE_TRACKING_EVENT);
    }

    private unpackResponse(data:GameInfo): void {
        delete data.volatility;
        delete data.teaser;

        // if all properties are empty mark as failed.
        if (Object.values(data).every(x => x === null || x === '')) {
            this.hasFailed = true;
        }

        this.betRange = data.betRange;
        this.realRTP = data.realRtp;
        this.theoreticalRTP = data.theoreticalRtp;
    }

    private getPostbackUrl(): string {
        const params = getAvailabilityParams();
        const searchParams = new URLSearchParams({
            currency: params.currency,
            language: params.language,
            country: params.country,
            site_id: params.siteId.toString(),
            site_code: params.siteCode,
            player_flag: params.playerFlag.toString(),
        });

        return `${this.getHost()}/ajax/game-meta-data/${this.gameUuid}?${searchParams.toString()}`;
    }

    private getHost() {
        return (window.location.hostname !== 'localhost')
            ? '/fragment/gamefilter'
            : '';
    }

    private gameLaunchHandler = () => issueLaunchGameEvent(this.getLaunchOrigin(), this.displayName, this.position, 'game_trigger--overlay');

    private pushTracking(event:string): void {
        pushToDataLayer({
            event: event,
            gameName: this.displayName,
            gamePosition: this.position,
            origin: this.launchOrigin,
        });
    }

    private getLaunchOrigin(): string {
        return `${this.launchOrigin}-gametile-menu`;
    }
}

if (!customElements.get('game-info-overlay')) {
    customElements.define('game-info-overlay', GameInfoOverlay);
}
