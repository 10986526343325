import {html, css, TemplateResult} from "lit";
import {property} from "lit/decorators.js";
import {LitElement} from "lit";
import {badgeCss} from "../common/styles/badge.css";
import {BaccaratTable, LiveGameUpdate} from "../models/LiveCasino";

const GT_TABLE_DATA_UPDATED = 'live-casino-data';

export class GameBaccaratBadges extends LitElement {
    static override styles = [
        badgeCss,
        css`

        :host {
            container-type: inline-size;
            container-name: baccarat-badges;
            display: block;
        }

        .game-baccarat-wrapper {
            margin-bottom: 5px;
            display: flex;
            justify-content: flex-start;
        }

        .game-baccarat-badges {
            width: 24px;
            height: 24px;
            border-radius: 24px;
            background-color: rgba(0, 0, 0, 0.8);
            font-size: 12px;
            place-content: center;
            font-weight: 700;
            text-align: center;
            margin: 0 2px 2px 0;
            display: none;
            font-family: "Lucida Grande","Lucida Sans Unicode","Geneva","Verdana",sans-serif;
        }

        .badge-tie {
            color: #008D3B;
        }

        .badge-bank {
            color: #EE0000;
        }

        .badge-player {
            color: #1A6FFF;
        }

        .badge-tie-first {
            color: #FFF;
            background-color: rgba(0, 141, 59, 0.7);
        }

        .badge-bank-first {
            color: #FFF;
            background-color: rgba(238, 0, 0, 0.7);
        }

        .badge-player-first {
            color: #FFF;
            background-color: rgba(0, 77, 209, 0.7);
        }

        .game-baccarat-badges:nth-child(-n+4) {
            display: grid;
        }

        @container baccarat-badges (min-width: 131px) {
            .game-baccarat-badges:nth-child(-n+5) {
                display: grid;
            }
        }

        @container baccarat-badges (min-width: 156px) {
            .game-baccarat-badges:nth-child(-n+6) {
                display: grid;
            }
        }

        @container baccarat-badges (min-width: 181px) {
            .game-baccarat-badges:nth-child(-n+7) {
                display: grid;
            }
        }

        @container baccarat-badges (min-width: 206px) {
            .game-baccarat-badges:nth-child(-n+8) {
                display: grid;
            }
        }

        @container baccarat-badges (min-width: 231px) {
            .game-baccarat-badges:nth-child(-n+9) {
                display: grid;
            }
        }

        @container baccarat-badges (min-width: 256px) {
            .game-baccarat-badges:nth-child(-n+10) {
                display: grid;
            }
        }
      `
    ];

    @property({type: String, attribute: 'game-uuid'})
    private gameUuid= '';

    @property({type: Object, attribute: 'live-data'})
    private liveData: BaccaratTable = {
        dealer:'',
        type:'BACCARAT',
        activePlayers: 0,
        enabled: true,
        history: [],
    };

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent<LiveGameUpdate>) {
        if (!top?.location.origin.includes(event?.origin)) {
            return;
        }

        const eventData = event?.data;
        if (eventData?.type !== GT_TABLE_DATA_UPDATED) {
            return;
        }

        if (eventData.message) {
            const updateData = eventData.message[this.gameUuid];
            if(updateData && updateData.type === this.liveData.type){
                this.liveData = updateData;
            }
        }
    }

    private renderBaccaratBadges() {
        return this.liveData.history.map((letter, index)  => {
            const className = this.selectColorClass(letter);
            if(className !== 'invalid') {
                return html`
                    <div class="game-baccarat-badges ${index === 0 ? className + '-first' : className}">
                        ${ letter }
                    </div>
                `
            }
            return html``;
        });
    }

    private selectColorClass(letter:string) {
        switch(letter) {
            case 'T': 
                return 'badge-tie';
            case 'B': 
                return 'badge-bank';
            case 'P': 
                return 'badge-player';
            default: 
                return 'invalid';
        }
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="game-baccarat-wrapper">
                ${ this.renderBaccaratBadges() }
            </div>
        `;
    }
}

if (!customElements.get('game-baccarat-badges')) {
    customElements.define('game-baccarat-badges', GameBaccaratBadges);
}

