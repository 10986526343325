import {css} from "lit";

export const actionsCss = css`
    .touch-target {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: var(--gff-touch-target-bg-color);
        border-radius: 24px;
        z-index: 2;
        position: relative;
        text-decoration: none;
    }

    .favourite-action__icon {
        display: flex;
        justify-content: center;
        background-image: var(--gff-favourite-inactive-image);
        background-repeat: no-repeat;
        background-position: center center;
        width: 10px;
        height: 9px;
        transition: transform 0.2s cubic-bezier(.47,1.64,.41,.8);
    }

    .favourite-action-icon--small-animation {
        transform: scale(0.9);
    }

    .favourite-action-icon--big-animation {
        transform: scale(2);
    }

    .favourite-action__icon.favourite-action__icon--active {
        background-image: var(--gff-favourite-active-image);
        width: 16px;
        height: 14px;
    }
    
    .touch-target .touch-target__action {
        position: absolute;
        height: 40px;
        width: 40px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }
    
    .touch-target.touch-target--themed span {
        color: var(--gff-touch-target-txt-color);
        font-size: 19px;
    }
    
    .touch-target.touch-target--themed path {
        fill: var(--gff-touch-target-txt-color);
    }
    
    .touch-target.touch-target--themed:hover path {
        fill: var(--gff-touch-target-txt-color-hover);
    }
`;